<template>
  <div>
    <a-spin :spinning="isLoading">
      <b-row class="m-1">
        <b-col sm="6" class="mb-1">
          <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-1">
              <feather-icon icon="UsersIcon" size="14" />
              <span class="align-middle ml-50">Matched Stations</span>
              </b-card-title>
            </b-card-header>
            <b-row class="pl-1 pr-1 mt-1 mb-1">
              <b-col sm="12">
                <div >
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="8"
                        label-cols-lg="4"
                        label="Region"
                        label-for="search-region"
                      >
                        <v-select 
                          id="search-region" 
                          v-on:input="getSelectedRegion(`${regionSelected}`)"
                          v-model="regionSelected" 
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                          :options="regionOptions" 
                          class="select-size-sm" 
                          style="margin-top:-2px; background:white" 
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12"> 
                      <b-form-group
                        label-cols="8"
                        label-cols-lg="4"
                        label="Province"
                        label-for="search-propinsi"
                      >
                        <v-select 
                          id="search-propinsi" 
                          v-on:input="getSelectedPropinsi(`${propinsiSelected}`)"
                          v-model="propinsiSelected" 
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                          :options="propinsiOptions" 
                          class="select-size-sm" 
                          style="margin-top:-2px; background:white" 
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

                <vue-good-table
                  :columns="columnsAvailable"
                  :rows="rowsAvailable"
                  :isLoading="showLoading"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage:pageLength,
                    setCurrentPage: 1,
                    mode: 'pages'
                  }"
                  theme="polar-bear"
                > 
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'action'">
                      <span >
                      <b-button
                      :disabled="props.row.status !== undefined && props.row.status == false"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-primary"
                        class="btn-icon"
                        @click="addElement(props.row)"
                      >
                        <feather-icon icon="PlusCircleIcon" />
                      </b-button>
                        
                      </span>
                    </span>
                  </template>
                  
                </vue-good-table>
              </b-col>
            </b-row>

          </b-card>
        </b-col>

        <b-col sm="6" class="mb-1">
          <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-1">
              <feather-icon icon="UsersIcon" size="14" />
              <span class="align-middle ml-50">Selected Stations</span>
              </b-card-title>
            </b-card-header>
            <b-row class="pl-1 pr-1 mt-1 mb-1">
              <b-col sm="12">
                <div class="custom-search d-flex justify-content-end">
                  <b-row class="mt-0">
                    <b-col cols="12" class="mb-1">
                      <b-button
                        variant="primary"
                        class="mb-1 mb-sm-0 mr-0 mr-sm-1 "
                        size="md"
                        block
                        v-on:click="onUpdateStation()"
                      >
                        Update
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <vue-good-table
                  :columns="columnsSelected"
                  :rows="rowsSelected"
                  :search-options="{
                    enabled: true,
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                    setCurrentPage: 1,
                    mode: 'pages'
                  }"
                >
                  <template slot="table-row" slot-scope="props">

                    <span v-if="props.column.field === 'action'">
                      <span>
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-danger"
                          class="btn-icon"
                          @click="removeElement(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      
                      </span>
                    </span>

                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>
    </a-spin>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BButton
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import metadata from "@/api/metadata";
import Ripple from 'vue-ripple-directive'
import rulesetApi from "@/api/ruleset";
export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    BFormGroup,
    BCardHeader,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    VueGoodTable,
    vSelect
  },
  directives: {
    Ripple,
  },
  props: ["selectedRuleset"],
  data() {
    return {
      listStationSelected: [],
      searchTermSelected: '',
      searchTerm: '',
      paginationCurrentPage: 10,
			pageLength: 10,
      showLoading: false,
      isLoading: false,
      columnsSelected: [
				{
					label: "Stations",
					field: "station_name",
          thClass: "text-center",
				},
				{
					label: "Action",
					field: "action",
					thClass: "text-center",
					tdClass: "text-center"
				},
			],
      rowsSelected: [],
      columnsAvailable: [
				{
					label: "Stations",
					field: "station_name",
          thClass: "text-center",
				},
				{
					label: "Action",
					field: "action",
					thClass: "text-center",
					tdClass: "text-center"
				},
			],
      rowsAvailable: [],
      regionOptions: [],
      regionSelected: null,
      propinsiOptions: [],
      propinsiSelected: null,
      isRoleEdit: true,
    };
  },
  methods: {

    async onUpdateStation() {
      if(this.rowsSelected && this.rowsSelected.length > 0 ) {
        let postStation = []
        this.rowsSelected.forEach(function(element) {
          postStation.push(element.station_id)
        })

        try {
          this.isLoading = true
          let post_data = {   
            "ruleset_stations" : postStation
          }
          let id = this.selectedRuleset.id
          const { data } = await rulesetApi.editRuleset(id, post_data);
          console.log('data', data) 
          this.selectedRuleset.ruleset_stations = postStation
          
          this.$swal({
            title: "Update Ruleset!",
            text: "Update Ruleset Berhasil!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$emit("updateRulesetStation", this.selectedRuleset);

          this.isLoading = false
        } catch (e) {
          console.log(e);
        }
        
      } 
    },

    addElement(event) {
      let tempRows = [...this.rowsSelected]

      if (event) {
        let exist = tempRows.find(item => item.station_id == event.station_id) 
        if (!exist) {
          tempRows.push({station_id: event.station_id, station_name: event.station_name})
        }
      }
      this.rowsSelected = tempRows
      
			var item = this.rowsAvailable.find(item => item.station_id == event.station_id);
      item['status'] = false
      console.log('item', item )
		},

    removeElement(event) {

      if (event) {
        this.rowsSelected = this.rowsSelected.filter(function(item) {
            return item.station_id !== event.station_id
        })
      }
		},

    async getSelectedRegion(value) {
      if (value !== null && value !== 'null') {
        let region_id = this.regionSelected?.value
        if (region_id) {
          try {
            const { data } = await metadata.getPropinsiListByRegion(region_id);
            for (var i = 0; i < data.length; i++) {
              const rowsPropinsi = {
                value: data[i].propinsi_id,
                label: data[i].propinsi_name,
              };
              this.propinsiOptions.push(rowsPropinsi);
            }
          } catch (e) {
            console.log('catch',e);
          }

          this.getStationByRegion(region_id);
        }
      }
      else {
        console.log('getStations')
        this.getStations();
      }
    },
     

    async getSelectedPropinsi(value) {
      // console.log('getSelectedPropinsi', value)
      if (value !== null && value !== 'null') {
        let propinsi_id = this.propinsiSelected?.value
        if (propinsi_id) {
          try {
            this.showLoading = true
            const { data } = await metadata.getStasiunWithIDNamebyPropinsi(propinsi_id);
            this.rowsAvailable = data
            this.showLoading = false
          } catch (e) {
            console.log(e);
          }
        }
      }
      else {
        if (this.regionSelected) {
          let region_id = this.regionSelected.value
          this.getStationByRegion(region_id);
        }
      }
    },

    async getStationByRegion(region_id) {
      try {
        this.showLoading = true
        const { data } = await metadata.getStasiunWithIDNamebyRegion(region_id);
        this.rowsAvailable = data
        this.showLoading = false
      } catch (e) {
        console.log(e);
      }
    },
    
    async getStations() {
      // console.log('getStations')
      let allstations = JSON.parse(localStorage.getItem('list_station_ruleset'))
      try {
        this.showLoading = true
        if(allstations === null) {
          const { data } = await metadata.getAllStasiunWithIDName();
          localStorage.setItem('list_station_ruleset', JSON.stringify(data))
          allstations = data
        }
        this.rowsAvailable = allstations
        this.showLoading = false
      } catch (e) {
        console.log(e);
      }
    },

    async getRegion() {
      try {
        const { data } = await metadata.getRegionList();
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const dataRegion = {
            value: data[i].region_id,
            label: data[i].region_description,
          };
          this.regionOptions.push(dataRegion);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getPropinsi(region_id) {
      var arr = this.t_path.split("/");
      var region_id = arr[1];
      this.propinsi_opt = [{ value: "", text: "Pilih Propinsi" }];
      
    },

    checkStationAvailable() {
      if (this.selectedRuleset && this.selectedRuleset.ruleset_stations) {
        let listStationSelected = this.selectedRuleset.ruleset_stations
        if (listStationSelected && listStationSelected.length > 0) {
          this.rowsSelected = this.rowsAvailable.filter(function(item) {
            let st_id = false
            for (var sid of listStationSelected) {
               if (item.station_id == sid) {
                st_id = true
                break;
               }
            }
            return st_id;
          })
        }
        else {
          this.rowsSelected = []
        }
      }
      else {
        this.rowsSelected = []
      }
    },
  },

  mounted() {
    this.getStations();
    this.getRegion();
    // this.getPropinsi();
  },

  watch: {
    selectedRuleset: function(val) {
      this.checkStationAvailable()
    }
  },

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>

