<template>
  <div>
    <b-card>
      <h4>Quality Control - Ruleset</h4>
      <b-row>
        <b-col sm="2">
          <b-card no-body class="border mt-1">
            <b-button
              v-if="isLoading"
              variant="secondary"
              disabled
              class="mr-1"
            >
              
              Loading...
            </b-button>
            <b-list-group>
              <b-spinner v-if="isLoading"
                type="grow"
              />
              <template v-for="(item, index) in list_ruleset">
                <b-list-group-item href="#" v-on:click="clickedOnRuleset(item.id)" :key="`tabkey-${index}`">{{item.ruleset_name}}</b-list-group-item>
              </template>
            </b-list-group>
          </b-card>
        </b-col>

        <b-col sm="10">
          <b-card no-body class="border mt-1" style="">
              <div class="">
                <b-tabs v-model="step" align="left">

                  <b-tab active title="Ruleset" >
                    <item-ruleset 
                      @rulesetName="onUpdateRulesetName($event)" 
                      @rulesetForm="onUpdateRulesetForm($event)" 
                      @rulesetParameter="onUpdateRulesetParameter($event)" 
                      @updateNewRuleset="onUpdateNewRuleset($event)"
                      @updateRulesetOld="onUpdateRulesetOld($event)"
                      @deleteRuleset="onDeleteRuleset($event)" 
                      :selectedRuleset="selectedRuleset"
                    />
                  </b-tab>

                  <b-tab title="Station"  :disabled="selectedRuleset == null">
                    <station-ruleset 
                      :selectedRuleset="selectedRuleset"
                      @updateRulesetStation="updateRulesetStation($event)"
                      lazy/>
                  </b-tab>

                  <b-tab title="Rule" :selectedRuleset="selectedRuleset" :disabled="selectedRuleset == null">
                    <rule-ruleset 
                      :selectedRuleset="selectedRuleset"
                      @updateRulesetListQC="updateRulesetListQC($event)"
                      lazy/>
                  </b-tab>

                </b-tabs>
            </div>
          </b-card>
          
          <!-- <b-button
            v-if="!selectedRuleset"
            variant="secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            size="sm"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            v-on:click="clickOnCancel()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="!selectedRuleset"
            variant="gradient-primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="reset"
            size="sm"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            v-on:click="clickOnNewRuleset()"
          >
            Add New Ruleset
          </b-button> -->

          <b-button
            v-if="selectedRuleset"
            variant="secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            size="sm"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            v-on:click="clickOnCancelUpdate()"
          >
            Cancel
          </b-button>
          <!-- <b-button
            v-if="selectedRuleset"
            variant="gradient-primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="reset"
            size="sm"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            v-on:click="clickOnUpdate()"
          >
            Update
          </b-button> -->
        
        </b-col>
      </b-row>
      
    </b-card>
      

  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BListGroup, 
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BTabs, BTab ,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ItemRuleset from "./ItemRuleset.vue";
import StationRuleset from "./StationRuleset.vue";
import RuleRuleset from "./RuleRuleset.vue";
import rulesetApi from "@/api/ruleset";
import trendVue from '../../inputdata/meteorologi/metarspecicomponent/trend.vue';
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BListGroup, BListGroupItem,
    BTabs, BTab,
    vSelect,
    ItemRuleset,
    StationRuleset,
    RuleRuleset,
  },

  data() {
    return {
      step: 0,
      isLoading: false,
      list_ruleset: [
        // {ruleset_id:'1', ruleset_name: 'Ruleset AA'}, {ruleset_id:'2', ruleset_name: 'Ruleset BB'}
      ],
      selectedRuleset: null
    }
  },

  mounted() {
    this.getListRuleset();
    // this.getRegion();
  },
  
  methods: {

    onDeleteRuleset(object) {
      // console.log('onDeleteRuleset', object)
      this.list_ruleset = this.list_ruleset.filter((obj) => obj.id != object.id)
      this.selectedRuleset = null
    },

    

    onUpdateNewRuleset(object) {
      // console.log('object', object)
      if (object) {
        this.list_ruleset.push(object)
      }
    },

    onUpdateRulesetOld(object) {
      console.log('object', object)
      if (object) {
        this.list_ruleset.forEach(element => {
          if (element.id == object.id) {
            element.ruleset_name = object.ruleset_name
            element.ruleset_desc = object.ruleset_desc
            element.ruleset_parameter = object.ruleset_parameter
          }
        });
      }
    },

    updateRulesetListQC(object) {
      console.log('object', object)
      if (object) {
        this.list_ruleset.forEach(element => {
          if (element.id == object.id) {
            element.ruleset_stations = object.ruleset_stations
          }
        });
      }
    },

    updateRulesetStation(object) {
      if (object) {
        this.list_ruleset.forEach(element => {
          if (element.id == object.id) {
            element.ruleset_rules = object.ruleset_rules
          }
        });
      }
    },

    clickedOnRuleset(event) {
      let exist = this.list_ruleset.find(item => item.id == event) 
      if (exist) {
        this.selectedRuleset = null
        this.selectedRuleset = exist
        this.step = 0
        console.log('clickedOnRuleset', this.selectedRuleset)
      }
    },

    clickOnCancelUpdate() {
      this.selectedRuleset = null
    },

    clickOnUpdate() {

    },

    clickOnCancel() {

    },

    clickOnNewRuleset() {

    },

    async getListRuleset() {
      // console.log('getListRuleset')
      try {
        this.isLoading = true
        const { data } = await rulesetApi.listRuleset();
        this.list_ruleset = data
        this.isLoading = false
      } catch (e) {
        console.log(e);
      }
    },

  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
