<template>
  <div>
    <a-spin :spinning="isLoading">
      <b-row class="m-1 pt-1">
        <b-col sm="4">
          <b-button
            variant="primary"
            block
            @click="addRangeCheck()"
          >
          <!-- <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="addRangeCheck(props.row)"
          > -->
            Add Range Check
          </b-button>
        </b-col>
        <b-col sm="4">
          <b-button
            variant="primary"
            block
            @click="addStepCheck()"
          >
            Add Step Check
          </b-button>
        </b-col>
        <b-col sm="4">
          <b-button
            variant="primary"
            block
            @click="addConsistencyCheck()"
          >
            Add Consistency Check
          </b-button>
        </b-col>
      </b-row> 

      <b-row  class="m-1">
        <b-col sm="4">
          <h6 class="mb-1 mt-1">List QC</h6>
          <b-card no-body class="border mt-1">
            <b-list-group>
              <template v-for="(item, index) in list_qc">
                <b-list-group-item href="#" v-on:click="clickedOnItemQC(item, index)" :key="`tabkey-${index}`">{{item.rs_qc_name}}</b-list-group-item>
              </template>
            </b-list-group>

          </b-card>
        </b-col>
        
        <!-- QC RANGECHECK -->
        <b-col v-if="list_qc && list_qc.length > 0 && selectedQCIndex >= 0 && list_qc[selectedQCIndex].rs_qc_type == 'range_check'" sm="8" class="">
          <b-card no-body class="border mt-3 p-2">
            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="9"
                    label-cols-lg="3"
                    label="RANGE CHECK "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="ml-1 "
                      v-model="list_qc[selectedQCIndex].rs_qc_name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="11"
                    label-cols-lg="1"
                    label="THE"
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="ml-1 font-weight-bolder"
                      v-model="selectedQCParameter"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="2"
                    label-cols-lg="10"
                    label="REPORT SUSPECT IF VALUE IS LESS THAN OR EQUAL TO "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="ml-1 text-center"
                      v-model="list_qc[selectedQCIndex].rs_qc_rc_suspect_lte"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="2"
                    label-cols-lg="10"
                    label="OR MORE THAN OR EQUAL TO "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="ml-1 text-center"
                      v-model="list_qc[selectedQCIndex].rs_qc_rc_suspect_gte"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="2"
                    label-cols-lg="10"
                    label="REPORT ERROR IF VALUE IS LESS THAN OR EQUAL TO "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="ml-1 text-center"
                      v-model="list_qc[selectedQCIndex].rs_qc_rc_error_lte"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="2"
                    label-cols-lg="10"
                    label="OR MORE THAN OR EQUAL TO "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="ml-1 text-center"
                      v-model="list_qc[selectedQCIndex].rs_qc_rc_error_gte"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="12">
                  <b-button
                    v-if="selectedRuleset"
                    variant="danger"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
                    size="md"
                    v-on:click="clickOnRemove(selectedQCIndex)"
                  >
                    Remove
                  </b-button>
                </b-col>
              </b-row>
            </div>

          </b-card>
        </b-col>

        <!-- QC STEPCHECK -->
        <b-col v-if="list_qc && list_qc.length > 0 && selectedQCIndex >= 0 && list_qc[selectedQCIndex].rs_qc_type == 'step_check'" sm="8" class="">
          <b-card no-body class="border mt-3 p-2">
            <div class="">
              <b-row>
                <b-col cols="8">
                  <b-form-group
                    label-cols="9"
                    label-cols-lg="3"
                    label="STEP CHECK "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class=""
                      v-model="list_qc[selectedQCIndex].rs_qc_name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label-cols="10"
                    label-cols-lg="2"
                    label="THE"
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="ml-1 font-weight-bolder"
                      v-model="selectedQCParameter"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="6" style="color:#000">
                  <b-form-group
                    label-cols="0"
                    label-cols-lg="12"
                    label="COMPARE WITH PREVIOUS RECEIVED VALUE"
                    label-for="input-sm"
                    class=""
                  />
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="8">
                  <b-form-group
                    label-cols="3"
                    label-cols-lg="9"
                    label="REPORT SUSPECT IF VALUE EXCEED "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="text-center"
                      v-model="list_qc[selectedQCIndex].rs_qc_sc_suspect_exceed"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="8">
                  <b-form-group
                    label-cols="3"
                    label-cols-lg="9"
                    label="REPORT ERROR IF VALUE EXCEED "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="text-center"
                      v-model="list_qc[selectedQCIndex].rs_qc_sc_error_exceed"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="8">
                  <b-form-group
                    label-cols="3"
                    label-cols-lg="9"
                    label="AT FREQUENCY "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class="text-center"
                      value="3600"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-group
                    label-cols="3"
                    label-cols-lg="9"
                    label="SEC (S) "
                    label-for="input-sm"
                    class=""
                  > </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="12">
                  <b-button
                    v-if="selectedRuleset"
                    variant="danger"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
                    size="md"
                    v-on:click="clickOnRemove(selectedQCIndex)"
                  >
                    Remove
                  </b-button>
                </b-col>
              </b-row>
            </div>

          </b-card>
        </b-col>

        <!-- QC CONSISTENCY CHECK -->
        <b-col v-if="list_qc && list_qc.length > 0 && selectedQCIndex >= 0 && list_qc[selectedQCIndex].rs_qc_type == 'consistency_check'" sm="8" class="">
          <b-card no-body class="border mt-3 p-2">
            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="9"
                    label-cols-lg="5"
                    label="CONSISTENCY CHECK "
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class=""
                      v-model="list_qc[selectedQCIndex].rs_qc_name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="10"
                    label-cols-lg="2"
                    label="FOR"
                    label-for="input-sm"
                    class=""
                  >
                    <b-form-input
                      id="input-sm"
                      class=" font-weight-bolder"
                      v-model="selectedQCParameter"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="2"
                    label-cols-lg="10"
                    label="SUSPECT CONDITION: "
                    label-for="input-sm"
                    class=""
                  >
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="MN-1">
              <b-row class="MN-1">
                <b-col cols="9">
                  <b-form-textarea
                    id="textarea-default"
                    rows="3"
                    v-model="list_qc[selectedQCIndex].rs_qc_cc_suspect"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="mt-2">
              <b-row>
                <b-col cols="9">
                  <b-form-group
                    label-cols="2"
                    label-cols-lg="10"
                    label="ERROR CONDITION: "
                    label-for="input-sm"
                    class=""
                  >
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="9">
                  <b-form-textarea
                    id="textarea-default"
                    rows="3"
                    v-model="list_qc[selectedQCIndex].rs_qc_cc_error"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="">
              <b-row>
                <b-col cols="12">
                  <b-button
                    v-if="selectedRuleset"
                    variant="danger"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
                    size="md"
                    v-on:click="clickOnRemove(selectedQCIndex)"
                  >
                    Remove
                  </b-button>
                </b-col>
              </b-row>
            </div>

          </b-card>

        </b-col>

      </b-row>

      <b-row class="m-1">
        <b-col cols="12" > 
          <b-button
            variant="primary"
            class="float-right"
            size="md"
            v-on:click="clickOnSaveQCList()"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </a-spin>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BButton,
  BListGroup, 
  BListGroupItem,
  BFormInput,
  BFormGroup,
  BFormTextarea
} from "bootstrap-vue";
import rulesetApi from "@/api/ruleset";
export default {
  components: {
    BTab,
    BTabs,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BButton,
    BListGroup, 
    BListGroupItem,
    BFormInput,
    BFormGroup,
    BFormTextarea
  },
  props: ["selectedRuleset"],
  data() {
    return {
      isLoading: false,
      list_qc: [
        // {qc_id:'1', qc_type: 'RC', qc_name: 'Range Check'}, 
        // {qc_id:'2', qc_type: 'SC', qc_name: 'Step Check'},
        // {qc_id:'3', qc_type: 'CC', qc_name: 'Consistency Check'}
      ],
      selectedQC: null,
      selectedQCIndex: -1,
      ruleset_rules: null,
      selectedQCParameter: null
    };
  },

  watch: {
    selectedRuleset: function(val) {
      if (val) {
        this.checkRuleAvailable()
      }
      else {
        this.list_qc = []
      }
    }
  },

  methods: {

    async clickOnSaveQCList() {
      console.log('clickOnSaveQCList', this.list_qc)
      this.ruleset_rules = null
      if (this.list_qc) {
        if (this.list_qc.length > 0) {
          this.ruleset_rules = this.list_qc
        }
        else {
          this.ruleset_rules = null
        }
      }

      try {
        this.isLoading = true
        let post_data = {   
          "ruleset_rules" : this.ruleset_rules
        }
        let id = this.selectedRuleset.id
        const { data } = await rulesetApi.editRuleset(id, post_data);
        console.log('data', data) 
        this.selectedRuleset.ruleset_stations = this.list_qc
        
        this.$swal({
          title: "Update Ruleset!",
          text: "Update Ruleset Berhasil!",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.$emit("updateRulesetListQC", this.selectedRuleset);

        this.isLoading = false
      } catch (e) {
        console.log(e);
      } 
    },

    clickOnRemove(index) {
      console.log('clickOnRemove index', index)
      this.list_qc.splice(index, 1);
      this.selectedQCIndex = 0
    },

    toCamelCase(str){
      let newStr = "";
      if(str){
        let wordArr = str.split(/[-_]/g);
        for (let i in wordArr){
          newStr += wordArr[i].toUpperCase() + " "
        }
      }else{
        return newStr
      }
      return newStr;
    },

    checkRuleAvailable() {
      console.log('checkRuleAvailable', this.selectedRuleset)
      
      if (this.selectedRuleset) {
        
        if (this.selectedRuleset.ruleset_parameter) {
          this.selectedQCParameter = this.toCamelCase(this.selectedRuleset.ruleset_parameter_alias)
        }
        else {
          this.selectedQCParameter = null
        }

        if (this.selectedRuleset.ruleset_rules) {
          this.list_qc = this.selectedRuleset.ruleset_rules
        }
        else {
          this.list_qc = []
        }
      }
      else {
        this.list_qc = []
        this.selectedQCParameter = null
      }
    },

    addRangeCheck() {
      let exist = this.list_qc.find(item => item.rs_qc_type == 'range_check') 
      if (!exist) {
        // this.list_qc.push({rs_qc_name:'1', rs_qc_type: 'RC', qc_name: 'Range Check'})
        let qc = {
          rs_qc_name: "RC",
          rs_qc_type: "range_check",
          rs_qc_cc_error: null,
          rs_qc_cc_suspect: null,
          rs_qc_rc_error_gte: null,
          rs_qc_rc_error_lte: null,
          rs_qc_rc_suspect_gte: null,
          rs_qc_rc_suspect_lte: null,
          rs_qc_sc_error_exceed: null,
          rs_qc_sc_suspect_exceed: null
        }
        this.list_qc.push(qc)
      }
    },

    addStepCheck() {
      let exist = this.list_qc.find(item => item.rs_qc_type == 'step_check') 
      if (!exist) {
        // this.list_qc.push({qc_id:'2', qc_type: 'SC', qc_name: 'Step Check'})
        let qc = {
          rs_qc_name: "SC",
          rs_qc_type: "step_check",
          rs_qc_cc_error: null,
          rs_qc_cc_suspect: null,
          rs_qc_rc_error_gte: null,
          rs_qc_rc_error_lte: null,
          rs_qc_rc_suspect_gte: null,
          rs_qc_rc_suspect_lte: null,
          rs_qc_sc_error_exceed: null,
          rs_qc_sc_suspect_exceed: null
        }
        this.list_qc.push(qc)
      }
    },

    addConsistencyCheck() {
      let exist = this.list_qc.find(item => item.rs_qc_type == 'consistency_check') 
      if (!exist) {
        // this.list_qc.push({qc_id:'3', qc_type: 'CC', qc_name: 'Consistency Check'})
        let qc = {
          rs_qc_name: "CC",
          rs_qc_type: "consistency_check",
          rs_qc_cc_error: null,
          rs_qc_cc_suspect: null,
          rs_qc_rc_error_gte: null,
          rs_qc_rc_error_lte: null,
          rs_qc_rc_suspect_gte: null,
          rs_qc_rc_suspect_lte: null,
          rs_qc_sc_error_exceed: null,
          rs_qc_sc_suspect_exceed: null
        }
        this.list_qc.push(qc)
      }
    },

    clickedOnItemQC(event, index) {
      console.log('clickedOnItemQC', event)
      this.selectedQC = event
      this.selectedQCIndex = index
    },

  },
  mounted() {
    
  },
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
