var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('b-row',{staticClass:"m-1"},[_c('b-col',{staticClass:"mb-1",attrs:{"sm":"6"}},[_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-1"},[_c('feather-icon',{attrs:{"icon":"UsersIcon","size":"14"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Matched Stations")])],1)],1),_c('b-row',{staticClass:"pl-1 pr-1 mt-1 mb-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"8","label-cols-lg":"4","label":"Region","label-for":"search-region"}},[_c('v-select',{staticClass:"select-size-sm",staticStyle:{"margin-top":"-2px","background":"white"},attrs:{"id":"search-region","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.regionOptions},on:{"input":function($event){return _vm.getSelectedRegion(("" + _vm.regionSelected))}},model:{value:(_vm.regionSelected),callback:function ($$v) {_vm.regionSelected=$$v},expression:"regionSelected"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols":"8","label-cols-lg":"4","label":"Province","label-for":"search-propinsi"}},[_c('v-select',{staticClass:"select-size-sm",staticStyle:{"margin-top":"-2px","background":"white"},attrs:{"id":"search-propinsi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.propinsiOptions},on:{"input":function($event){return _vm.getSelectedPropinsi(("" + _vm.propinsiSelected))}},model:{value:(_vm.propinsiSelected),callback:function ($$v) {_vm.propinsiSelected=$$v},expression:"propinsiSelected"}})],1)],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columnsAvailable,"rows":_vm.rowsAvailable,"isLoading":_vm.showLoading,"search-options":{
                  enabled: true,
                  externalQuery: _vm.searchTerm,
                },"pagination-options":{
                  enabled: true,
                  perPage:_vm.pageLength,
                  setCurrentPage: 1,
                  mode: 'pages'
                },"theme":"polar-bear"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"disabled":props.row.status !== undefined && props.row.status == false,"variant":"flat-primary"},on:{"click":function($event){return _vm.addElement(props.row)}}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon"}})],1)],1)]):_vm._e()]}}])})],1)],1)],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"6"}},[_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-1"},[_c('feather-icon',{attrs:{"icon":"UsersIcon","size":"14"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Selected Stations")])],1)],1),_c('b-row',{staticClass:"pl-1 pr-1 mt-1 mb-1"},[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-row',{staticClass:"mt-0"},[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1 ",attrs:{"variant":"primary","size":"md","block":""},on:{"click":function($event){return _vm.onUpdateStation()}}},[_vm._v(" Update ")])],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columnsSelected,"rows":_vm.rowsSelected,"search-options":{
                  enabled: true,
                },"pagination-options":{
                  enabled: true,
                  perPage: _vm.pageLength,
                  setCurrentPage: 1,
                  mode: 'pages'
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.removeElement(props.row)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }