<template>
  <div>
    <b-row>
      <b-col sm="12">
        <a-spin :spinning="isLoading">
          <b-card no-body class="border m-1" style="margin-top:30px;">
            
            <b-row class="ml-1 mt-1">
              <b-col cols="7">
                  <b-form-group
                    label-cols="10"
                    label-cols-lg="3"
                    label="Name: "
                    label-for="input-rulesetname"
                  >
                    <b-form-input
                      id="input-rulesetname"
                      v-model="rulesetName" 
                    />
                  </b-form-group>
              </b-col>
            </b-row>

            <b-row class="ml-1">
              <b-col cols="7">
                  <b-form-group 
                    label-cols="10"
                    label-cols-lg="3"
                    label="Form Type :" 
                    label-for="search-form">
                    <v-select 
                      id="search-usage"
                      v-on:input="getSelectedForm(`${formSelected.value}`)"
                      v-model="formSelected" 
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                      :options="formOptions" 
                      class="select-size-sm" 
                      style="margin-top:-2px; background:white" 
                    />
                  </b-form-group>
              </b-col>
            </b-row>

            <b-row class="ml-1 mb-1">
              <b-col cols="7">
                  <b-form-group 
                    label-cols="10"
                    label-cols-lg="3"
                    label="Element Type :" 
                    label-for="search-element">
                    <v-select 
                      id="search-usage"
                      v-on:input="getSelectedElement(`${elementSelected.value}`)"
                      v-model="elementSelected" 
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                      :options="elementOptions" 
                      class="select-size-sm" 
                      style="margin-top:-2px; background:white" 
                    />
                  </b-form-group>
              </b-col>
            </b-row>

            <b-row class="m-1">  
              <b-col cols="3"/>
              <b-col cols="2">
                <b-button
                  v-if="!selectedRuleset"
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  size="md"
                  block
                  v-on:click="clickOnClear()"
                >
                  Clear
                </b-button>

                <b-button
                  v-if="selectedRuleset"
                  variant="danger"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  size="md"
                  block
                  v-on:click="clickOnRemove()"
                >
                  Remove
                </b-button>

              </b-col>
              <b-col cols="2">
                <b-button
                  v-if="!selectedRuleset"
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  size="md"
                  block
                  v-on:click="clickOnCreateNew()"
                >
                  Create New
                </b-button>

                <b-button
                  v-if="selectedRuleset"
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  size="md"
                  block
                  v-on:click="clickOnUpdate()"
                >
                  Update
                </b-button>

              </b-col>

            </b-row>
            
          </b-card>
        </a-spin>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import helper from "@/api/helper";
import rulesetApi from "@/api/ruleset";
export default {
  components: {
    BButton,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    BFormGroup,
    BFormInput,
    vSelect
  },
  props: ["selectedRuleset"],
  data() {
    return {
      isLoading: false,
      rulesetName: null,
      rulesetForm: null,
      rulesetParameter: null,
      elementSelected: null,
      elementOptions: [],
      formSelected: null,
      formOptions: [
        { value: null, label: '' },
        { value: '1', label: 'Sinoptik', id: 'sinoptik' },
        { value: '2', label: 'Iklim Mikro', id: 'iklimmikro' },
        { value: '3', label: 'Fklim', id: 'fklim' },
        { value: '4', label: 'Agm1a', id: 'agm1a' },
        { value: '5', label: 'Agm1b', id: 'agm1b' },
        { value: '6', label: 'Psychrometer Assman', id: 'psychrometerassman' },
        { value: '7', label: 'Suhu Tanah', id: 'suhutanah' },
        { value: '8', label: 'OP Penguapan', id: 'oppenguapan' },
        { value: '9', label: 'Lysimeter', id: 'lysimeter' },
        { value: '10', label: 'Piche Penguapan', id: 'pichepenguapan' },
        { value: '11', label: 'Gunbellani', id: 'gunbellani' },
        { value: '12', label: 'Pibal', id: 'pibal' },
        { value: '13', label: 'Metar Speci', id: 'metarspeci' },
        { value: '14', label: 'Lama Penyinaran', id: 'lamapenyinaran' },
        { value: '15', label: 'Thermohigograph', id: 'thermohigograph' },
        { value: '16', label: 'Aktinograph', id: 'aktinograph' },
        { value: '17', label: 'Form Hujan', id: 'formhujan' },
        { value: '18', label: 'Barograph', id: 'barograph' },
        { value: '19', label: 'Hujan Hellman', id: 'hujanhellman' },
        { value: '20', label: 'Form Perawanan', id: 'formperawanan' },
        { value: '21', label: 'Fenologi', id: 'fenologi' },
        { value: '22', label: 'Kelembaban Tanah', id: 'kelembabantanah' }
      ]
    };
  },
  methods: {

    async clickOnRemove() {
      // console.log('selectedRuleset', this.selectedRuleset)
      let subMessage = this.selectedRuleset.ruleset_name
      this.$swal({
            title: "Delete ruleset "+subMessage,
            text: "Apakah anda yakin untuk menghapus data ini ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText : "Tidak",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              try {
                this.isLoading = true
                const { data } = rulesetApi.deleteRuleset(this.selectedRuleset.id);
                console.log('data', data)
                this.$emit("deleteRuleset", this.selectedRuleset);
                this.isLoading = false
                this.$swal({
                  title: "Delete Ruleset!",
                  text: "Delete Ruleset Berhasil!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              } catch (e) {
                console.log(e);
              }
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
      
    },

    async clickOnUpdate() {
      try {
        this.isLoading = true
        let post_data = {   
          "ruleset_name" : this.rulesetName,
          "ruleset_desc" : this.formSelected.id, 
          "ruleset_parameter" : this.elementSelected.value,
          "ruleset_parameter_alias": this.elementSelected.alias
        }
        let id = this.selectedRuleset.id
        const { data } = await rulesetApi.editRuleset(id, post_data);
        
        this.$swal({
          title: "Update Ruleset!",
          text: "Update Ruleset Berhasil!",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        this.selectedRuleset.ruleset_name = this.rulesetName
        this.selectedRuleset.ruleset_desc = this.formSelected.id
        this.selectedRuleset.ruleset_parameter = this.elementSelected.value
        this.selectedRuleset.ruleset_parameter_alias = this.elementSelected.alias
        
        this.$emit("updateRulesetOld", this.selectedRuleset);

        // this.clickOnClear()
        this.isLoading = false
      } catch (e) {
        console.log(e);
      }
    },

    clickOnClear() {
      this.rulesetName = null
      this.rulesetForm = null
      this.rulesetParameter = null
      this.formSelected = null
      this.elementSelected = null
    },

    async clickOnCreateNew() {
      let id = this.rulesetName.replace(/\s/g, '')
      try {
        this.isLoading = true
        let post_data = {   
          "@type" : "Ruleset",
          "id" : id,
          "ruleset_name" : this.rulesetName,
          "ruleset_desc" : this.formSelected.id, 
          "ruleset_parameter" : this.elementSelected.value,
          "ruleset_parameter_alias": this.elementSelected.alias
        }

        const { data } = await rulesetApi.createRuleset(post_data);
        console.log('data', data)

        post_data["ruleset_stations"] = null
        post_data["ruleset_rules"] = null
        
        this.$emit("updateNewRuleset", post_data);

        this.clickOnClear()
        this.isLoading = false
      } catch (e) {
        console.log(e);
      }
    },

    async getSelectedForm(val) {
      // console.log('getSelectedForm', val)
      if (val) {
        try {
          const { data } = await helper.getParameterAliasQC(val);
          let options = []
          if (data && data.length > 0) {
            let params = data[0]
            for(var form in params) {
              let paramForm = params[form]
              let strForm = form.toUpperCase()
              for (var param of paramForm) {
                let strParam = param.alias
                let entry = param.parameter_entry
                let alias = param.alias
                let camelCased = this.toCamelCase(strParam);
                options.push({ value: param.parameter, label: strForm+' - '+camelCased, desc:entry, alias:alias })
              }

            }
          }
          this.elementOptions = options

          if (this.rulesetParameter) { 
            let elementExist = this.elementOptions.find(item => item.value == this.rulesetParameter) 
            this.elementSelected = elementExist
          }
          
        } catch (e) {
          console.log(e);
        }
      }
    },

    toCamelCase(str){
      let newStr = "";
      if(str){
        let wordArr = str.split(/[-_]/g);
        for (let i in wordArr){
          newStr += wordArr[i].toUpperCase() + " "
        }
      }else{
        return newStr
      }
      return newStr;
    },

    getSelectedElement() {

    },

    async loadRuleset() {
      // console.log('this.selectedRuleset', this.selectedRuleset)
      if (this.selectedRuleset) {
        this.rulesetName = this.selectedRuleset.ruleset_name
        this.rulesetForm = this.selectedRuleset.ruleset_desc
        this.rulesetParameter = this.selectedRuleset.ruleset_parameter

        let formExist = this.formOptions.find(item => item.id == this.rulesetForm) 
        this.formSelected = formExist

        let elementExist = this.elementOptions.find(item => item.value == this.rulesetParameter) 
        this.elementSelected = elementExist

        if (this.formSelected) {
          this.getSelectedForm(this.formSelected.value)
        }
      }
      else {
        this.rulesetName = null
        this.rulesetForm = null
        this.rulesetParameter = null
        this.formSelected = null
        this.elementSelected = null
      }
		},
    
  },

  watch: {
    selectedRuleset: function(val) {
      this.loadRuleset()
    }
  },

  mounted() {
    // this.loadRuleset()
  },
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
