import { helpers } from "vuelidate/lib/validators";
export function GC_WIND_DIRECTION(value) {
    return !helpers.req(value) || (value >= 0 && value <= 360) || value == 9999;
}
export function GC_WIND_SPEED(value) {
    return !helpers.req(value) || (value >= 0 && value <= 50) || value == 9999;
}
export function GC_WIND_GUST(value) {
    return !helpers.req(value) || (value >= 0 && value <= 65) || value == 9999;
}
export function GC_CLOUD_HEIGHT(value) {
    return !helpers.req(value) || (value >= 0 && value <= 25000) || value == 9999;
}
export function GC_VERTICAL_VIS(value) {
    return !helpers.req(value) || value >= 0;
}
export function GC_AIR_TEMP(value) {
    return !helpers.req(value) || (value >= 5 && value <= 40) || value == 9999;
}
export function GC_DEW_POINT(value) {
    return !helpers.req(value) || (value >= 0 && value <= 50) || value == 9999;
}
export function GC_PREASSURE(value) {
    return !helpers.req(value) || (value >= 800 && value <= 1100) || value == 9999;
}
export function GC_SEA_SURFACE_TEMP(value) {
    return !helpers.req(value) || (value >= 0 && value <= 50) || value == 9999;
}
export function GC_VISIBILITY_PREVAILING(value) {
    return !helpers.req(value) || (value >= 50 && value <= 15000) || value == 9999 || value === 10000;
}
export function GC_VISIBILITY_MINIMUM(value) {
    return !helpers.req(value) || (value >= 0 && value <= 15000) || value == 9999;
}
export function validationPerN(n) {
    // validasi kalau value itu habis dibagi 'n'
    return (value) => !helpers.req(value) || value == Math.round(value / n) * n;
}

// Range Check
const RC = {
    wind_speed: { min: 0, max: 30 },
    wind_gust: { min: 0, max: 60 },
    cloud_height: { min: 0, max: 20000 },
};

export function RC_METAR(value, key) {
    let status = false;
    let message = "";
    let min = RC[key].min;
    let max = RC[key].max;

    if (value == "" || (value >= min && value <= max)) {
        status = true;
        message = "";
    } else {
        status = false;
        message = "RC - Batas Bawah " + min + ", Batas Atas " + max;
    }

    return { status: status, message: message };
}
