import axios from 'axios'
import helper from './helper';
export default {

    async createRuleset(post_data) {
        const url = helper.getBaseUrl() + "/ruleset"
        return await axios.post(url, post_data, helper.getConfig())
    },

    async editRuleset(id, post_data) {
        const url = helper.getBaseUrl() + "/ruleset/" + id
        return await axios.patch(url, post_data, helper.getConfig())
    },

    async listRuleset() {
        const url = helper.getBaseUrl() + "/ruleset/@aggregation_query?type_name=Ruleset&_metadata=id,ruleset_name,ruleset_desc,ruleset_parameter,ruleset_stations,ruleset_rules,ruleset_parameter_alias"
        return await axios.get(url, helper.getConfig())
    },

    async deleteRuleset(id){
        const url = helper.getBaseUrl()+ "/ruleset/" + id
        return axios.delete(url, helper.getConfig())
    },

    async monitoringRuleset(ruleset, date) {
        const url = helper.getBaseUrl() + "/@monitoring_ruleset?id="+ ruleset +"&date=" + date
        return axios.get(url, helper.getConfig())
    }

}
