<template>
  <div class="" style="font-size: 0.9rem">
    <div class="row">
      <div class="col-xl-4">
        <b-card class=" bodered bg-light-primary">
          <div>
            <b-row class="">
              <b-col sm="4">
                <label class=""> Trend <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="indikator perubahan" /> </label>
              </b-col>
              <b-col sm="5" class="pl-1">
                <b-form-select id="input-type" v-model="trend_data.trend" class="mb-0" size="sm" :disabled="disabled.trend">
                  <option value="">-</option>
                  <option v-for="(data, i) in options.trend" :key="i" :value="data">
                    {{ data }}
                  </option>
                </b-form-select>
              </b-col>
            </b-row>

            <div class="row my-1">
              <b-col sm="4">
                <label class="font-weight-bolder text-primary"> Timegroup </label>
              </b-col>
            </div>

            <div class="row">
              <b-col sm="4">
                <label class=""> Indicator <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Notasi pengenal waktu"/></label>
              </b-col>
              <b-col sm="4">
                <label class=""> Time </label>
              </b-col>
            </div>

            <div class="row">
              <b-col sm="4" class="pl-1">
                <b-form-select id="input-indicator_1" v-model="trend_data.time_group.indicator_1" class="mb-0" size="sm" :disabled="disabled.time_group.indicator_1">
                  <option value="">-</option>
                  <option v-for="(data, i) in options.time_group.indicator" :key="i" :value="data">
                    {{ data }}
                  </option>
                </b-form-select>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="input-time_1"
                  v-model="$v.trend_data.time_group.time_1.$model"
                  :state="validateState($v.trend_data.time_group.time_1)"
                  size="sm"
                  :disabled="disabled.time_group.time_1"
                  placeholder="HHMM"
                  class="text-center"
                ></b-form-input>
              </b-col>
            </div>
            <div class="row" style="margin-top: 4px">
              <b-col sm="4">
                <b-form-input id="input-indicator_2" v-model="trend_data.time_group.indicator_2" size="sm" :disabled="disabled.time_group.indicator_2"></b-form-input>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="input-time_2"
                  v-model="$v.trend_data.time_group.time_2.$model"
                  :state="validateState($v.trend_data.time_group.time_2)"
                  size="sm"
                  :disabled="disabled.time_group.time_2"
                  placeholder="HHMM"
                  class="text-center"
                ></b-form-input>
              </b-col>
            </div>
            <p class="my-1">
              * The format of time is HHMM
            </p>
          </div>
        </b-card>

        <b-card class="bodered">
          <b-row class="">
            <b-col sm="12" class="font-weight-bolder text-warning"> ANGIN </b-col>
          </b-row>
          <div>
            <b-row class="mt-1" style="margin-bottom: 5px">
              <b-col sm="6">
                <label class=""> Arah Angin (derajat)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Arah angin di bulatkan ke puluhan derajat terdekat"/></label>
              </b-col>
              <b-col sm="5" class="pl-1">
                <b-form-input
                  id="input-direction"
                  class="text-center"
                  :state="validateState($v.trend_data.winds.direction)"
                  v-model.number="$v.trend_data.winds.direction.$model"
                  type="number"
                  v-on:keydown="onKeyCheck($event)"
                  size="sm"
                  :disabled="disabled.winds.direction"
                  @keydown="formatInteger"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row style="margin-bottom: 5px">
              <b-col sm="6">
                <label class=""> Kecepatan Angin (knot)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kecepatan angin dalam Knot" /> </label>
              </b-col>
              <b-col sm="5" class="pl-1">
                <b-form-input
                  :state="validateState($v.trend_data.winds.speed)"
                  v-model.number="$v.trend_data.winds.speed.$model"
                  type="number"
                  v-on:keydown="onKeyCheck($event)"
                  size="sm"
                  :disabled="disabled.winds.speed"
                  :class="{ 'text-center': true, rangecheck: T_RC.t_wind_speed.status }"
                  id="wind_speed"
                  @keyup="checkValidate($event, 'wind_speed')"
                  @keydown="formatInteger"
                ></b-form-input>
                <b-tooltip v-if="T_RC.t_wind_speed.status" target="wind_speed" triggers="hover">
                  {{ T_RC.t_wind_speed.message }}
                </b-tooltip>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <label class="">
                  Gust (Knot)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kecepatan angin meningkat 10 knot atau lebih dari  kecepatan angin rata rata" />
                </label>
              </b-col>
              <b-col sm="5" class="pl-1">
                <b-form-input
                  :state="validateState($v.trend_data.winds.gust)"
                  v-model.number="$v.trend_data.winds.gust.$model"
                  type="number"
                  v-on:keydown="onKeyCheck($event)"
                  size="sm"
                  :disabled="disabled.winds.gust"
                  :class="{ 'text-center': true, rangecheck: T_RC.t_wind_gust.status }"
                  id="wind_gust"
                  @keyup="checkValidate($event, 'wind_gust')"
                  @keydown="formatInteger"
                ></b-form-input>
                <b-tooltip v-if="T_RC.t_wind_gust.status" target="wind_gust" triggers="hover">
                  {{ T_RC.t_wind_gust.message }}
                </b-tooltip>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>

      <div class="col-xl-3">
        <b-card class="bodered">
          <b-row class="">
            <b-col sm="12" class="font-weight-bolder text-primary"> CAVOK </b-col>
          </b-row>
          <b-row class="mt-1 mb-2">
            <b-col sm="8">
              <label for="checkbox-rcavok" class=""
                >Report CAVOK <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltipsA" />
                <!-- HTML tooltips -->
                <b-tooltip target="tooltipsA" triger="hover" placement="top">
                  <dl class="text-left">
                    <dt>Catatan</dt>
                    <dd>- Jarak pandang mendatar 10 km atau lebih</dd>
                    <dd>- Tidak ada cuaca bermakna</dd>
                    <dd>- Tidak ada awan dibawah 1500 meter (5000 feet)</dd>
                    <dd>- tidak ada awan CB</dd>
                  </dl>
                </b-tooltip></label
              >
            </b-col>
            <b-col sm="4" class="pl-1">
              <b-form-checkbox id="checkbox-rcavok" v-model="trend_data.status.CAVOK" name="checkbox-rcavok" size="md" :disabled="disabled.status.CAVOK"> </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="bodered">
          <b-row class="">
            <b-col sm="12" class="font-weight-bolder text-primary"> VISIBILITY </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col sm="6">
              <label for="checkbox-cavok" class="">Prevailling (m)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jarak pandang mendatar "/></label>
            </b-col>
            <b-col sm="6" class="pl-1">
              <b-form-input
                id="input-prevailing"
                class="text-center"
                :state="validateState($v.trend_data.prevailing)"
                v-model.number="$v.trend_data.prevailing.$model"
                type="number"
                  v-on:keydown="onKeyCheck($event)"
                size="sm"
                :disabled="disabled.prevailing"
                @keydown="formatInteger"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="bodered">
          <b-row class="">
            <b-col sm="8">
              <label for="checkbox-nsw" class="">No Significant Weather (NSW)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tidak ada cuaca bermakna"/></label>
            </b-col>
            <b-col sm="4" class="pl-1">
              <b-form-checkbox id="checkbox-nsw" v-model="trend_data.weather.NSW" name="checkbox-nsw" size="md" :disabled="disabled.weather.NSW"> </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-3">
            <b-col sm="6">
              <label for="checkbox-cavok" class="">Forecast Weather<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="perkiraan phenomena cuaca di bandara "/></label>
            </b-col>
            <b-col sm="6" class="pl-1">
              <!-- button for popup -->
              <b-button variant="outline-secondary" class="w-100 button-weather" size="sm" @click="openWeatherModal()" :disabled="disabled.forecast_weather">
                {{ trend_data.forecast_weather || "&nbsp;" }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div class="col-xl-5">
        <b-card class=" bodered">
          <b-row class="mb-2">
            <b-col sm="12" class="font-weight-bolder text-success"> AWAN </b-col>
          </b-row>
          <div>
            <b-row>
              <b-col sm="6">
                <label for="checkbox-nsc" class=""
                  >No Significant Cloud (NSC)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tidak ada awan dengan ketinggian 1500 meter"
                /></label>
              </b-col>
              <b-col sm="4" class="pl-1">
                <b-form-checkbox id="checkbox-nsc" v-model="trend_data.weather.NSC" name="checkbox-nsc" size="md" :disabled="disabled.weather.NSC"> </b-form-checkbox>
              </b-col>
            </b-row>
            <div class="mt-2">
              <div class="table-responsive text-nowrap">
                <b-table striped hover small fixed :items="[{}, ...trend_data.clouds.items]" :fields="tables.clouds.fields" class="my-table">
                  <!-- row pertama buat input, jadi items dikasih object kosong di row pertama -->
                  <template v-slot:head(jumlah)="data">
                    <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Jumlah perawanan (oktas)" /> </span>
                  </template>
                  <template v-slot:head(tinggi)="data">
                    <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Tinggi dasar awan (feet)" /> </span>
                  </template>
                  <template v-slot:head(type)="data">
                    <span>{{ data.label.toUpperCase() }} <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark.top title="Jenis Awan" /> </span>
                  </template>
                  <template #cell(jumlah)="data">
                    <b-form-select v-if="data.index == 0" id="clouds-jumlah" v-model="trend_data.clouds.jumlah" class="mb-0" size="sm" :disabled="disabled.clouds.jumlah">
                      <option value="">-</option>
                      <option v-for="option in options.clouds.jumlah_input" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </option>
                    </b-form-select>
                    <div v-else-if="data.index - 1 === tables.clouds.edit_index">
                      <b-form-select v-model="trend_data.clouds.items[tables.clouds.edit_index].jumlah" class="mb-0" size="sm">
                        <option value="">-</option>
                        <option v-for="option in options.clouds.jumlah" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </option>
                      </b-form-select>
                    </div>
                    <label v-else>{{ data.value }}</label>
                  </template>

                  <template #cell(tinggi)="data">
                    <b-tooltip v-if="T_RC.t_cloud_height.status" target="cloud_height" triggers="hover">
                      {{ T_RC.t_cloud_height.message }}
                    </b-tooltip>
                    <b-form-input
                      v-if="data.index == 0"
                      :state="validateState($v.trend_data.clouds.tinggi)"
                      v-model.number="$v.trend_data.clouds.tinggi.$model"
                      type="number"
                  v-on:keydown="onKeyCheck($event)"
                      size="sm"
                      :disabled="disabled.clouds.tinggi"
                      :class="{ 'text-center': true, rangecheck: T_RC.t_cloud_height.status }"
                      id="cloud_height"
                      @keyup="checkValidate($event, 'cloud_height')"
                      @keydown="formatInteger"
                    ></b-form-input>
                    <div v-else-if="data.index - 1 === tables.clouds.edit_index">
                      <b-form-input
                        :state="validateState($v.trend_data.clouds.items.$each[tables.clouds.edit_index].tinggi)"
                        v-model.number="$v.trend_data.clouds.items.$each[tables.clouds.edit_index].tinggi.$model"
                        type="number"
                  v-on:keydown="onKeyCheck($event)"
                        size="sm"
                        class="text-center"
                        @keydown="formatInteger"
                      ></b-form-input>
                    </div>
                    <label v-else>{{ data.value }}</label>
                  </template>

                  <template #cell(type)="data">
                    <b-form-select v-if="data.index == 0" id="select-type" v-model="trend_data.clouds.type" class="mb-0" size="sm" :disabled="disabled.clouds.type">
                      <option value="">-</option>
                      <option v-for="(option, i) in options.clouds.type" :key="i" :value="option">
                        {{ option }}
                      </option>
                    </b-form-select>
                    <div v-else-if="data.index - 1 === tables.clouds.edit_index">
                      <b-form-select v-model="trend_data.clouds.items[tables.clouds.edit_index].type" class="mb-0" size="sm">
                        <option value="">-</option>
                        <option v-for="(option, i) in options.clouds.type" :key="i" :value="option">
                          {{ option }}
                        </option>
                      </b-form-select>
                    </div>
                    <label v-else>{{ data.value }}</label>
                  </template>

                  <template #cell(action)="data">
                    <b-button v-if="data.index == 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon rounded-circle" size="sm" @click="clouds_add()" :disabled="disabled.clouds.button">
                      <feather-icon icon="PlusIcon" />
                    </b-button>

                    <div v-else>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon rounded-circle" size="sm" @click="clouds_edit(data.index - 1)">
                        <feather-icon icon="SaveIcon" v-if="data.index - 1 === tables.clouds.edit_index" />
                        <feather-icon icon="EditIcon" v-else />
                      </b-button>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon rounded-circle ml-1" size="sm" @click="clouds_delete(data.index - 1)">
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-xl-4">
                <div><label for="clouds-vertical-vis">VERTICAL VIS</label><feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="jarak pandang keatas (feet)" /></div>
              </div>
              <div class="col-xl-3">
                <b-form-input
                  id="clouds-vertical-vis"
                  class="text-center"
                  :state="validateState($v.trend_data.clouds.vertical_vis)"
                  v-model.number="$v.trend_data.clouds.vertical_vis.$model"
                  type="number"
                  size="sm"
                  :disabled="disabled.clouds.vertical_vis"
                  @keydown="formatInteger"
                ></b-form-input>
              </div>
              <div class="col-xl-5 ">
                <div>
                  <label for="clouds-vertical-vis"></label>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <!-- MODAL FOR FORECAST WEATHER -->
    <weather-modal ref="weather-modal" :weathers_modal.sync="trend_data.forecast_modal" :code.sync="trend_data.forecast_weather" :prevailing="trend_data.prevailing"></weather-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BFormInput,
  BContainer,
  BButton,
  BCard,
  BFormTimepicker,
  BFormDatepicker,
  BFormTextarea,
  BIcon,
  BFormSelect,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,
  VBToggle,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import WeatherModal from "./WeatherModalTrend";
import { validationMixin } from "vuelidate";
import { minValue, minLength, between } from "vuelidate/lib/validators";
import { GC_WIND_DIRECTION, GC_WIND_SPEED, GC_WIND_GUST, GC_VISIBILITY_PREVAILING, GC_VERTICAL_VIS, GC_CLOUD_HEIGHT, RC_METAR, validationPerN } from "@/validators/metarvalidator";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    BIcon,
    BFormSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormRadioGroup,
    BTooltip,
    WeatherModal,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    ripple: Ripple,
  },
  props: ["jam", "menit", "isFilterComplete", "RC_helper"],
  data() {
    return {
      trend_data: {
        trend: "",
        time_group: {
          indicator_1: "",
          indicator_2: "",
          time_1: "",
          time_2: "",
        },
        winds: {
          direction: "",
          speed: "",
          gust: "",
        },
        status: {
          CAVOK: false,
        },
        prevailing: "",
        weather: {
          NSW: false,
          NSC: false,
        },
        forecast_weather: "",
        forecast_modal: {
          group_1: "",
          group_2: "",
          group_3: "",
          group_4: "",
          group_5: "",
          none: "",
        },
        clouds: {
          jumlah: "",
          tinggi: "",
          type: "",
          vertical_vis: "",
          items: [
            // { jumlah: "FEW (1-2 oktas)", tinggi: "12", type: "CB" },
          ],
        },
      },
      T_RC: {
        t_wind_speed: { status: false, message: "" },
        t_wind_gust: { status: false, message: "" },
        t_cloud_height: { status: false, message: "" },
      },
      options: {
        trend: ["BECMG", "TEMPO", "NOSIG"],
        time_group: {
          indicator: ["FM", "TL", "AT"],
        },
        clouds: {
          jumlah_input: [
            { value: "FEW", text: "FEW (1-2 oktas)" },
            { value: "SCT", text: "SCT (3-4 oktas)" },
            { value: "BKN", text: "BKN (5-7 oktas)" },
            { value: "OVC", text: "OVC (8 oktas)" },
          ],
          jumlah: [
            { value: "FEW", text: "FEW (1-2 oktas)" },
            { value: "SCT", text: "SCT (3-4 oktas)" },
            { value: "BKN", text: "BKN (5-7 oktas)" },
            { value: "OVC", text: "OVC (8 oktas)" },
          ],
          type: ["CB", "TCU"],
        },
      },
      tables: {
        clouds: {
          fields: ["jumlah", { key: "tinggi", label: "tinggi (feet)" }, "type", "action"],
          max_items: 4,
          edit_index: -1,
        },
      },
    };
  },
  mixins: [validationMixin],
  validations() {
    return {
      trend_data: {
        time_group: {
          time_1: {
            minLength: minLength(4),
            selisih_waktu: this.selisih_waktu,
          },
          time_2: {
            minLength: minLength(4),
            selisih_waktu: this.selisih_waktu,
          },
        },
        prevailing: { GC_VISIBILITY_PREVAILING },
        winds: {
          direction: {
            GC_WIND_DIRECTION,
            validationPerN: validationPerN(10),
          },
          speed: { GC_WIND_SPEED },
          gust: {
            GC_WIND_GUST,
            minValue: minValue(this.trend_data.winds.speed + 10),
          },
        },
        clouds: {
          tinggi: { GC_CLOUD_HEIGHT },
          items: {
            $each: {
              tinggi: { GC_CLOUD_HEIGHT },
            },
          },
          vertical_vis: { GC_VERTICAL_VIS },
        },
      },
    };
  },

  computed: {
    disabled: function() {
      let all = this.trend_data.trend === "" || this.trend_data.trend === "NOSIG" || !this.isFilterComplete;

      return {
        trend: !this.isFilterComplete,
        time_group: {
          indicator_1: all || false,
          indicator_2: all || true,
          time_1: all || this.trend_data.time_group.indicator_1 === "",
          time_2: all || this.trend_data.time_group.indicator_1 !== "FM",
        },
        winds: {
          direction: all,
          speed: all,
          gust: all,
        },
        status: {
          CAVOK: all,
        },
        prevailing: all || this.trend_data.status.CAVOK,
        weather: {
          NSW: all || this.trend_data.status.CAVOK,
          NSC: all,
        },
        forecast_weather: all || this.trend_data.weather.NSW || this.trend_data.status.CAVOK,
        clouds: {
          jumlah: all || this.trend_data.weather.NSC,
          tinggi: all || this.trend_data.weather.NSC,
          type: all || this.trend_data.weather.NSC || this.trend_data.status.CAVOK,
          vertical_vis: all || this.trend_data.weather.NSC,
          button:
            all ||
            this.trend_data.weather.NSC ||
            !((this.trend_data.clouds.jumlah !== "" && this.trend_data.clouds.tinggi !== "" && this.trend_data.clouds.type !== "") || (this.trend_data.clouds.jumlah !== "" && this.trend_data.clouds.tinggi !== "")),
        },
      };
    },
  },
  methods: {
    onKeyCheck(event) {
      console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault()
      }
    },
    selisih_waktu(val) {
      const now = +this.jam * 60 + +this.menit;
      if (val?.length == 4) {
        const jam = val.substring(0, 2);
        const menit = val.substring(2);
        let test = +jam * 60 + +menit;
        if (+this.jam >= 22 && jam <= 2) {
          test += 24 * 60;
        }
        return test > now && test < now + 120;
      }
      return true;
    },
    formatInteger(event) {
      switch (event.key) {
        case "e":
        case ".":
        case ",":
          event.preventDefault();
          break;
      }
    },
    checkValidate: function(event, type) {
      let value = event.target.value;

      // GROSS CHECK
      let gc = false;
      switch (type) {
        case "wind_speed":
          gc = GC_WIND_SPEED(value);
          break;
        case "wind_gust":
          gc = GC_WIND_GUST(value);
          break;
        case "cloud_height":
          gc = GC_CLOUD_HEIGHT(value);
          break;
        default:
          break;
      }

      // RANGE CHECK
      let raw = { status: true, message: "" };
      switch (type) {
        case "wind_speed":
          raw = this.RC_helper.check(value, "m_wind_speed_ff");
          break;
        case "wind_gust":
          raw = this.RC_helper.check(value, "m_wind_gust_fmfm");
          break;
        case "cloud_height":
          raw = this.RC_helper.check(value, "m_cloud_height_hshshs");
          break;
        default:
          break;
      }

      // SET VALIDATION STATUS
      this.T_RC["t_" + type].status = gc && !raw.status;
      this.T_RC["t_" + type].message = raw.message;
      this.T_RC["t_" + type].value = value;
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },

    openWeatherModal() {
      this.$refs["weather-modal"].modal_data = this.trend_data.forecast_modal;
      this.$refs["weather-modal"].$refs["modal-component"].show();
    },
    clouds_add() {
      if (this.$v.trend_data.clouds.tinggi.$invalid) return false;

      if (this.trend_data.clouds.items.length < this.tables.clouds.max_items) {
        this.trend_data.clouds.items.push({
          jumlah: this.trend_data.clouds.jumlah,
          tinggi: this.trend_data.clouds.tinggi,
          type: this.trend_data.clouds.type,
        });
        this.trend_data.clouds.jumlah = "";
        this.trend_data.clouds.tinggi = "";
        this.trend_data.clouds.type = "";
      } else {
      }
    },
    clouds_edit(index) {
      if (this.tables.clouds.edit_index === index) {
        if (this.$v.trend_data.clouds.items.$each[this.tables.clouds.edit_index].tinggi.$invalid) return false;
        this.tables.clouds.edit_index = -1;
      } else {
        this.tables.clouds.edit_index = index;
      }
    },
    clouds_delete(index) {
      this.trend_data.clouds.items = this.trend_data.clouds.items.filter((item, i) => i !== index);
    },
  },
  watch: {
    // "trend_data.clouds.items": function(after) {
    //   const layer = after.length;
    //   const opt_1 = [
    //     { value: "FEW", text: "FEW (1-2 oktas)" },
    //     { value: "SCT", text: "SCT (3-4 oktas)" },
    //     { value: "BKN", text: "BKN (5-7 oktas)" },
    //     { value: "OVC", text: "OVC (8 oktas)" },
    //   ];
    //   const opt_2 = [
    //     { value: "SCT", text: "SCT (3-4 oktas)" },
    //     { value: "BKN", text: "BKN (5-7 oktas)" },
    //     { value: "OVC", text: "OVC (8 oktas)" },
    //   ];
    //   const opt_3 = [
    //     { value: "BKN", text: "BKN (5-7 oktas)" },
    //     { value: "OVC", text: "OVC (8 oktas)" },
    //   ];

    //   if (layer == 0) {
    //     this.options.clouds.jumlah_input = opt_1;
    //   }else if(layer == 1) {
    //     this.options.clouds.jumlah_input = opt_2;
    //   }else if(layer == 2) {
    //     this.options.clouds.jumlah_input = opt_3;
    //   }else if(layer == 3) {
    //     this.options.clouds.jumlah_input = opt_3;
    //   }
    // },
    "trend_data.trend": function(after, before) {
      if (after === "") {
        this.trend_data = {
          ...this.trend_data,
          time_group: {
            indicator_1: "",
            indicator_2: "",
            time_1: "",
            time_2: "",
          },
          winds: {
            direction: "",
            speed: "",
            gust: "",
          },
          status: {
            CAVOK: false,
          },
          prevailing: "",
          weather: {
            NSW: false,
            NSC: false,
          },
          forecast_weather: "",
          forecast_modal: {
            group_1: "",
            group_2: "",
            group_3: "",
            group_4: "",
            group_5: "",
            none: "",
          },
          clouds: {
            jumlah: "",
            tinggi: "",
            type: "",
            vertical_vis: "",
            items: [
              // { jumlah: "FEW (1-2 oktas)", tinggi: "12", type: "CB" },
            ],
          },
        };
      }
    },
    "trend_data.status.CAVOK": function(after, before) {
      if (after) {
        this.trend_data.prevailing = 10000;
        this.trend_data.weather.NSW = false;
      } else {
        this.trend_data.prevailing = "";
      }
    },
    "trend_data.time_group.indicator_1": function(after, before) {
      if (after === "FM") {
        this.trend_data.time_group.indicator_2 = "TL";
      } else {
        this.trend_data.time_group.indicator_2 = "";
        this.trend_data.time_group.time_1 = "";
        this.trend_data.time_group.time_2 = "";
      }
    },
    trend_data: {
      deep: true,
      handler() {
        this.$emit("update:trend_data", this.trend_data);
      },
    },
  },
};
</script>

<style scoped>
.rangecheck {
  border-color: rgb(248, 228, 72) !important;
  background-color: rgb(237, 252, 109) !important;
}

.custom-title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bodered {
  border: 1px solid #b4b7bd;
}

.label-entry {
  text-align: center;
  /* color: white; */
  width: 100%;
  /* font-weight: bold !important; */
  /* border: 1px solid #858585;; */
  /* border-radius: 3px; */
  /* background: #858585; */
  padding: 4px;
}

.hidden_header {
  display: none;
}

.my-table td {
  padding: 2px;
}

.button-weather:disabled {
  background: #efefef;
}
</style>
