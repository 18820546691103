<template>
    <b-modal
        title="Weather"
        centered
        size="xl"
        button-size="sm"
        ok-only
        no-close-on-backdrop
        class="p-2"
        ref="modal-component"
    >
        <b-container>
            <b-row>
                <b-col cols="5">
                    <b-row>
                        <b-col>
                            Qualifier
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="my-1">
                            (1) Intensity/Proximity
                            <b-form-radio-group
                                id="weather-group-1"
                                v-model="modal_data.group_1"
                                :options="options.group_1"
                                name="radio-intensity"
                                size="sm"
                                stacked
                                class="m-2"
                            ></b-form-radio-group>
                        </b-col>
                        <b-col class="my-1">
                            (2) Descriptor
                            <b-form-radio-group
                                id="weather-group-2"
                                v-model="modal_data.group_2"
                                :options="options.group_2"
                                name="radio-descriptor"
                                size="sm"
                                stacked
                                class="m-2"
                            ></b-form-radio-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="7">
                    <b-row>
                        <b-col>
                            Weather Phenomena
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="m-1">
                            <b-form-radio
                                id="checkbox-w-none"
                                name="checkbox-w-none"
                                size="sm"
                                inline
                                value="true"
                                v-model="modal_data.none"
                            >None</b-form-radio>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="my-1">
                            (3) Precipitation
                            <b-form-radio-group
                                id="weather-group-3"
                                v-model="modal_data.group_3"
                                :options="options.group_3"
                                name="radio-precipitation"
                                size="sm"
                                stacked
                                class="m-2"
                            ></b-form-radio-group>
                        </b-col>
                        <b-col class="my-1">
                            (4) Obscuration
                            <b-form-radio-group
                                id="weather-group-4"
                                v-model="modal_data.group_4"
                                :options="options.group_4"
                                name="radio-obscuration"
                                size="sm"
                                stacked
                                class="m-2"
                            ></b-form-radio-group>
                        </b-col>
                        <b-col class="my-1">
                            (5) Other
                            <b-form-radio-group
                                id="weather-group-5"
                                v-model="modal_data.group_5"
                                :options="options.group_5"
                                name="radio-other"
                                size="sm"
                                stacked
                                class="m-2"
                            ></b-form-radio-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
} from 'bootstrap-vue';

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BFormCheckbox,
        BFormRadio,
        BFormRadioGroup,
    },
    props: ["prevailing"],
    data() {
        return {
            modal_data: {
                group_1: "",
                group_2: "",
                group_3: "",
                group_4: "",
                group_5: "",
                none: "",
            },
        }
    },
    computed: {
        options() {
            // kumpulan kondisi
            // (1) Intensity/Proximity
            const MODER = this.modal_data.group_1 === "";
            const LIGHT = this.modal_data.group_1 === "-";
            const HEAVY = this.modal_data.group_1 === "+";
            const VC = this.modal_data.group_1 === "VC";

            // (2) Descriptor
            const G2 = this.modal_data.group_2 === "";
            const MI = this.modal_data.group_2 === "MI";
            const BC = this.modal_data.group_2 === "BC";
            const PR = this.modal_data.group_2 === "PR";
            const DR = this.modal_data.group_2 === "DR";
            const BL = this.modal_data.group_2 === "BL";
            const SH = this.modal_data.group_2 === "SH";
            const TS = this.modal_data.group_2 === "TS";
            const FZ = this.modal_data.group_2 === "FZ";

            // (3) Precipitation
            const G3 = this.modal_data.group_3 === "";
            const DZ = this.modal_data.group_3 === "DZ";
            const RA = this.modal_data.group_3 === "RA";
            const SN = this.modal_data.group_3 === "SN";
            const SG = this.modal_data.group_3 === "SG";
            const IC = this.modal_data.group_3 === "IC";
            const PE = this.modal_data.group_3 === "PE";
            const GR = this.modal_data.group_3 === "GR";
            const GS = this.modal_data.group_3 === "GS";
            const UP = this.modal_data.group_3 === "UP";

            // (4) Obscuration
            const G4 = this.modal_data.group_4 === "";
            const BR = this.modal_data.group_4 === "BR";
            const FG = this.modal_data.group_4 === "FG";
            const FU = this.modal_data.group_4 === "FU";
            const VA = this.modal_data.group_4 === "VA";
            const DU = this.modal_data.group_4 === "DU";
            const SA = this.modal_data.group_4 === "SA";
            const HZ = this.modal_data.group_4 === "HZ";

            // (5) Other
            const G5 = this.modal_data.group_5 === "";
            const PO = this.modal_data.group_5 === "PO";
            const SQ = this.modal_data.group_5 === "SQ";
            const FC = this.modal_data.group_5 === "FC";
            const SS = this.modal_data.group_5 === "SS";
            const DS = this.modal_data.group_5 === "DS";

            return {
                group_1: [
                    {
                        value: "",
                        text: "Moderate",
                        disabled: (
                            SH
                            && G3
                            && G4
                            && G5
                        ),
                    },
                    {
                        value: "-",
                        text: "- Light",
                        disabled: (
                            IC
                            || GR
                            || GS
                            && G3
                            && G4
                            && G5
                        )
                        || (
                            (TS && G3 && G4 && G5)
                            || (TS && GS)
                            || (TS && GR)
                            || (SH && G3 && G4 && G5)
                            || (SH && GR)
                            || (FZ && FG)
                        ),
                    },
                    {
                        value: "+",
                        text: "+ Heavy",
                        disabled: (
                            IC
                            || GR
                            || GS
                            && G3
                            && G4
                            && G5
                        )
                        || (
                            (TS && G3 && G4 && G5)
                            || (TS && GS)
                            || (TS && GR)
                            || (SH && G3 && G4 && G5)
                            || (SH && GR)
                            || (FZ && FG)
                        ),
                    },
                ],
                group_2: [
                    {
                        value: "",
                        text: "None",
                        disabled: false,
                    },
                    // {
                    //     value: "MI",
                    //     text: "MI Shallow",
                    //     disabled: (
                    //         DZ
                    //         || RA
                    //         || SN
                    //         || SG
                    //         || IC
                    //         || PE
                    //         || GR
                    //         || GS
                    //     )
                    //     || (
                    //         BR
                    //         || FU
                    //         || VA
                    //         || DU
                    //         || SA
                    //         || HZ
                    //     ),
                    // },
                    // {
                    //     value: "PR",
                    //     text: "PR Partial",
                    //     disabled: (
                    //         DZ
                    //         || RA
                    //         || SN
                    //         || SG
                    //         || IC
                    //         || PE
                    //         || GR
                    //         || GS
                    //     )
                    //     || (
                    //         BR
                    //         || FU
                    //         || VA
                    //         || DU
                    //         || SA
                    //         || HZ
                    //     ),
                    // },
                    // {
                    //     value: "BC",
                    //     text: "BC Patches",
                    //     disabled: (
                    //         DZ
                    //         || RA
                    //         || SN
                    //         || SG
                    //         || IC
                    //         || PE
                    //         || GR
                    //         || GS
                    //     )
                    //     || (
                    //         BR
                    //         || FU
                    //         || VA
                    //         || DU
                    //         || SA
                    //         || HZ
                    //     ),
                    // },
                    {
                        value: "DR",
                        text: "DR Low drifting",
                        disabled: (
                            DZ
                            || RA
                            || SG
                            || IC
                            || PE
                            || GR
                            || GS
                        )
                        || (
                            BR
                            || FG
                            || FU
                            || VA
                            || HZ
                        ),
                    },
                    {
                        value: "BL",
                        text: "BL Blowing",
                        disabled: (
                            DZ
                            || RA
                            || SG
                            || IC
                            || PE
                            || GR
                            || GS
                        )
                        || (
                            BR
                            || FG
                            || FU
                            || VA
                            || HZ
                        ),
                    },
                    {
                        value: "SH",
                        text: "SH Shower",
                        disabled: (
                            DZ
                            || SG
                            || IC
                        )
                        || (
                            BR
                            || FG
                            || FU
                            || VA
                            || DU
                            || SA
                            || HZ
                        ),
                    },
                    {
                        value: "TS",
                        text: "TS Thunderstorm",
                        disabled: (
                            DZ
                            || SG
                            || IC
                        )
                        || (
                            BR
                            || FG
                            || FU
                            || VA
                            || DU
                            || SA
                            || HZ
                        ),
                    },
                    {
                        value: "FZ",
                        text: "FZ Freezing",
                        disabled: (
                            SN
                            // || DZ
                            || SG
                            || IC
                            || PE
                            || GR
                            || GS
                        )
                        || (
                            BR
                            || FU
                            || VA
                            || DU
                            || SA
                            || HZ
                        ),
                    },
                ],
                group_3: [
                    {
                        value: "DZ",
                        text: "DZ Drizzle",
                        disabled: (
                            VC
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || DR
                            || BL
                            || SH
                            || TS
                            // || FZ
                        ),
                    },
                    {
                        value: "RA",
                        text: "RA Rain",
                        disabled: (
                            VC
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || DR
                            || BL
                        ),
                    },
                    {
                        value: "SN",
                        text: "SN Snow",
                        disabled: (
                            VC
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || FZ
                        ),
                    },
                    {
                        value: "SG",
                        text: "SG Snow grains",
                        disabled: (
                            VC
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || DR
                            || BL
                            || SH
                            || TS
                            || FZ
                        ),
                    },
                    // {
                    //     value: "IC",
                    //     text: "IC Ice crystals",
                    //     disabled: (
                    //         VC
                    //         || LIGHT
                    //         || HEAVY
                    //     )
                    //     || (
                    //         MI
                    //         || PR
                    //         || BC
                    //         || DR
                    //         || BL
                    //         || SH
                    //         || TS
                    //         || FZ
                    //     ),
                    // },
                    // {
                    //     value: "PE",
                    //     text: "PE Ice pellets",
                    //     disabled: (
                    //         VC
                    //     )
                    //     || (
                    //         MI
                    //         || PR
                    //         || BC
                    //         || DR
                    //         || BL
                    //         || FZ
                    //     ),
                    // },
                    {
                        value: "GR",
                        text: "GR Hail",
                        disabled: (
                            VC
                            || LIGHT
                            || HEAVY
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || DR
                            || BL
                            || FZ
                        ),
                    },
                    {
                        value: "GS",
                        text: "GS Small hail/snow pellets",
                        disabled: (
                            VC
                            || LIGHT
                            || HEAVY
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || DR
                            || BL
                            || FZ
                        ),
                    },
                    {
                        value: "UP",
                        text: "UP Unknown",
                        disabled: false,
                    },
                ],
                group_4: [
                    // {
                    //     value: "BR",
                    //     text: "BR Mist",
                    //     disabled: (
                    //         this.prevailing < 1000 || this.prevailing >= 5000
                    //     )
                    //     || (
                    //         LIGHT
                    //         || HEAVY
                    //         || VC
                    //     )
                    //     || (
                    //         MI
                    //         || PR
                    //         || BC
                    //         || DR
                    //         || BL
                    //         || SH
                    //         || TS
                    //         || FZ
                    //     ),
                    // },
                    {
                        value: "FG",
                        text: "FG Fog",
                        disabled: (
                            this.prevailing >= 1000
                        )
                        || (
                            LIGHT
                            || HEAVY
                        )
                        || (
                            DR
                            || BL
                            || SH
                            || TS
                        ),
                    },
                    // {
                    //     value: "FU",
                    //     text: "FU Smoke",
                    //     disabled: (
                    //         LIGHT
                    //         || HEAVY
                    //         || VC
                    //     )
                    //     || (
                    //         MI
                    //         || PR
                    //         || BC
                    //         || DR
                    //         || BL
                    //         || SH
                    //         || TS
                    //         || FZ
                    //     ),
                    // },
                    // {
                    //     value: "VA",
                    //     text: "VA Volcanic ash",
                    //     disabled: (
                    //         LIGHT
                    //         || HEAVY
                    //         || VC
                    //     )
                    //     || (
                    //         MI
                    //         || PR
                    //         || BC
                    //         || DR
                    //         || BL
                    //         || SH
                    //         || TS
                    //         || FZ
                    //     ),
                    // },
                    {
                        value: "DU",
                        text: "DU Widespread dust",
                        disabled: (
                            LIGHT
                            || HEAVY
                            || VC
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || SH
                            || TS
                            || FZ
                        ),
                    },
                    {
                        value: "SA",
                        text: "SA Sand",
                        disabled: (
                            LIGHT
                            || HEAVY
                            || VC
                        )
                        || (
                            MI
                            || PR
                            || BC
                            || SH
                            || TS
                            || FZ
                        ),
                    },
                    // {
                    //     value: "HZ",
                    //     text: "HZ Haze",
                    //     disabled: (
                    //         this.prevailing < 1000 || this.prevailing >= 5000
                    //     )
                    //     || (
                    //         LIGHT
                    //         || HEAVY
                    //         || VC
                    //     )
                    //     || (
                    //         MI
                    //         || PR
                    //         || BC
                    //         || DR
                    //         || BL
                    //         || SH
                    //         || TS
                    //         || FZ
                    //     ),
                    // },
                ],
                group_5: [
                    // {
                    //     value: "PO",
                    //     text: "PO Dust/sand whirls",
                    //     disabled: false,
                    // },
                    {
                        value: "SQ",
                        text: "SQ Squalls",
                        disabled: false,
                    },
                    {
                        value: "FC",
                        text: "FC Funnel cloud",
                        disabled: false,
                    },
                    {
                        value: "SS",
                        text: "SS Sandstorm",
                        disabled: false,
                    },
                    {
                        value: "DS",
                        text: "DS Duststorm",
                        disabled: false,
                    },
                ],
            }
        },
        code() {
            return this.modal_data.group_1
                + this.modal_data.group_2
                + this.modal_data.group_3
                + this.modal_data.group_4
                + this.modal_data.group_5;
        },
    },
    // methods: {
    //     checkDisabled() {
    //         for(let i = 1; i <= 5; i++) {
    //             const selected = this.options[`group_${i}`]?.find(item=>item.value === this.modal_data[`group_${i}`]);
    //             if(selected?.disabled) {
    //                 const next = this.options[`group_${i}`]?.find(item=>!item.disabled);
    //                 this.modal_data[`group_${i}`] = next ? next.value : "";
    //             }
    //         }
    //     }
    // },
    watch: {
        'modal_data.none'(after, before) {
            if(after) {
                this.modal_data.group_3 = "";
                this.modal_data.group_4 = "";
                this.modal_data.group_5 = "";
            }
        },
        'modal_data.group_1': function() {
            // this.checkDisabled();
        },
        'modal_data.group_2': function() {
            // this.checkDisabled();
        },
        'modal_data.group_3': function(after, before) {
            // this.checkDisabled();
            if(after) {
                this.modal_data.none = false;
                this.modal_data.group_4 = "";
                this.modal_data.group_5 = "";
            }
        },
        'modal_data.group_4': function(after, before) {
            // this.checkDisabled();
            if(after) {
                this.modal_data.none = false;
                this.modal_data.group_3 = "";
                this.modal_data.group_5 = "";
            }
        },
        'modal_data.group_5': function(after, before) {
            // this.checkDisabled();
            if(after) {
                this.modal_data.none = false;
                this.modal_data.group_3 = "";
                this.modal_data.group_4 = "";
            }
        },
        modal_data: {
            deep: true,
            handler() {
                this.$emit("update:modal_data", this.modal_data);
            },
        },
        code() {
            this.$emit("update:code", this.code);
        },
    }
}
</script>

<style>

</style>
